import React from 'react';
import {Helmet} from 'react-helmet';

const Head = ({title}) => {
	return (
		<>
			<Helmet

				defaultTitle="Dr Simeonsi dieedi tugikeskus"
				title={title}
			>
				<meta http-equiv="X-Content-Security-Policy" content="origin-when-cross-origin"/>
			</Helmet>
		</>
	)
}

export default Head;
